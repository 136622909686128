.modal-container {
  padding: 0.75rem;
  padding-bottom: 0.7rem;
  width: 25rem;
}

.modal-header {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--lunar-blue);
  margin-bottom: 1.5rem;
}

.modal-body {
  display: flex;
  font-size: var(--default-font-size);
  margin-bottom: 2.5rem;
}

.modal-buttons {
  text-align: right;
}

.modal-buttons button {
  width: 9.25rem;
}

.modal-icon {
  margin-right: 1.0625rem;
}

@media all and (max-width: 768px) {
  .modal-container {
    padding: 0.5rem;
    width: 20rem;
  }

  .modal-buttons button {
    width: 7.25rem;
  }
}

@media all and (max-width: 360px) {
  .modal-container {
    padding: 0.3rem;
    width: 19rem;
  }

  .modal-buttons button {
    width: 5.25rem;
  }
}

@media all and (max-width: 320px) {
  .modal-container {
    padding: 0.3rem;
    width: 17rem;
  }

  .modal-buttons button {
    width: 5.25rem;
  }

  .modal-header {
    font-size: var(--default-font-size);
    font-weight: 600;
    color: var(--lunar-blue);
    margin-bottom: 1.5rem;
  }

  .modal-body {
    display: flex;
    font-size: 0.7rem;
    margin-bottom: 2.5rem;
  }
}

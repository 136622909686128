.tabList {
  background-color: rgb(40, 57, 89);
  color: white;
  display: flex;
  flex-direction: row;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media all and (min-width: 1280px) {
  .tabs {
    width: 80rem;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (max-width: 768px) {
  .tabList {
    display: none;
  }
}

.viewreports-wrapper {
  height: 100%;
  display: table-row;
}

.table-header {
  margin-bottom: 0.875rem;
}

.history-table {
  margin-bottom: 1.16875rem;
}

.view-loader {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  margin-top: 3rem;
}

.empty-history {
  position: absolute;
  top: 40%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -40%);
  font-weight: 400;
  font-size: 1rem;
  color: var(--space-grey);
  text-align: center;
}

.empty-history-header {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}

.empty-history-text {
  width: 17.5rem;
  margin-top: 1rem;
  font-size: var(--default-font-size);
}

.mobile-empty-icon {
  display: none;
}

.table-date {
  margin-left: 0.4375rem;
  margin-bottom: 1.125rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--deep-space);
}

@media all and (max-width: 768px) {
  .table-date {
    margin-left: 0;
    margin-bottom: 0.6875rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .desktop-empty-icon {
    display: none;
  }

  .mobile-empty-icon {
    display: block;
  }

  .table-header {
    display: none;
  }

  .history-table {
    margin-bottom: 3.1875rem;
  }
}

.summary {
  margin-left: 61%;
  margin-top: 0.875rem;
}

.sum-remain {
  font-style: italic;
  font-weight: 400;
  margin-left: 0.4rem;
}

.sum {
  font-size: var(--default-font-size);
  font-weight: 600;
  color: var(--lunar-blue);
}

@media all and (max-width: 768px) {
  .summary {
    font-size: 0.875rem;
    margin-left: 0;
  }
}

.tab {
  height: 1.5625rem;
  color: white;
  font-size: var(--default-font-size);
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 7.5625rem;
}

.tab:hover {
  border-bottom: 0.25rem solid rgba(255, 166, 0, 0.342);
}

.tab.selected {
  font-weight: 600;
  border-bottom: 0.25rem solid var(--sunflare-orange);
}

@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
:root {
  touch-action: pan-x pan-y;
  height: 100%;

  --lunar-blue: #273859;
  --meteorite-grey: #7f8fb0;
  --sunflare-orange: #ff9714;
  --lunar-dust: #eaeffa;
  --starlight-blue: #e5ebf9;
  --space-grey: #526384;
  --sky-blue: #cedcf9;
  --cresent-blue: #006eab;
  --deep-space: #122344;
  --dark-space: #00021a;
  --comet-red: rgba(252, 60, 48, 0.15);
  --red: #fc3c30;
  --black: #202a3e;

  --default-font-size: 0.875rem;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaeffa;
  background-color: var(--lunar-dust);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  position: relative;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: calc(100% - 1rem);
  background: white;
  border-radius: 5px;
  margin: 0.5rem;
  padding-left: 1.0625rem;
  padding-right: 1.0625rem;
  padding-top: 1.6875rem;
  border: none;
  box-shadow: 0px 0px 15px rgba(214, 214, 214, 0.7);
}

.content-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

.welcome-text {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2.2rem;
  line-height: 1.531875rem;
}

.separator {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #7f8fb0;
  background-color: var(--meteorite-grey);
}

.error {
  background: rgba(252, 60, 48, 0.15) !important;
  background: var(--comet-red) !important;
  border: 1px solid #fc3c30 !important;
  border: 1px solid var(--red) !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-size: 1rem;
  color: #526384;
  color: var(--space-grey);
  text-align: center;
}

@media all and (min-width: 1280px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 76.625rem;
  }
}

@media all and (min-width: 768px) {
  .container {
    padding-bottom: 1.6875rem;
  }
}

@media all and (max-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: rgb(255, 255, 255, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 100px;
    background-color: #7f8fb0;
    background-color: var(--meteorite-grey);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

@media all and (max-height: 420px) {
  .content-container {
    padding-bottom: 6rem;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: table;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: var(--default-font-size);
}

.disabled {
  opacity: 0.6;
}

.table-container {
  margin: 0;
  padding: 0;
}

.table-loader {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-top: 5rem;
}

td {
  width: 14.375rem;
  min-height: 47px;
  display: flex;
  align-items: center;
  color: var(--black);
}

th {
  width: 14.375rem;
  text-align: left;
  font-size: 0.75rem;
  color: #7f8fb0;
  font-weight: 600;
  text-transform: uppercase;
}

th:first-child {
  padding-left: 0.4375rem;
}

th.discipline {
  width: 30%;
}

th.project {
  width: 30%;
}

th.hours {
  width: 8.5%;
}

th.comment {
  width: 27%;
}

th.options {
  width: 4%;
  min-width: 4%;
}

td.discipline {
  width: 30%;
  margin-right: 0.3125rem;
}

td.project {
  width: 30%;
}

td.hours {
  width: 8.5%;
}

td.comment {
  width: 27%;
}

td.options {
  text-align: center;
  width: 4%;
  min-width: 4%;
}

td * {
  display: flex;
  align-items: center;
}

.column-content {
  position: relative;
  width: 90%;
  word-wrap: break-word;
  align-self: center;
  vertical-align: middle;
  height: 100%;
  padding-left: 0.4rem;
}

.column-content.first {
  padding-left: 0.5rem;
}

.column-content.framed {
  height: 70%;
  width: 70% !important;
  padding-left: 0.4rem;
  border-radius: 3px;
  padding-left: -2px;
  border: 1px solid var(--meteorite-grey);
  cursor: pointer;
}

.allocation-input {
  display: flex;
  align-items: center;
  width: 70%;
  height: 2rem;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0.0625rem solid var(--meteorite-grey);
  border-radius: 0.1875rem;
  padding-left: 0.1rem;
}

.allocation-input:active {
  border: none;
}

tbody tr {
  transition: 0.2s;
  border-bottom: 1px solid rgba(172, 177, 188, 0.5);
}

tbody:last-child {
  border-top: 2px solid var(--meteorite-grey);
}

tbody:last-child {
  border-bottom: 2px solid var(--meteorite-grey);
}

.table-hover:hover {
  background-color: var(--sky-blue);
}

.table-delete {
  cursor: pointer;
  height: 1rem;
}

.empty-row {
  margin-top: 2.875rem;
}

.table-mobile-row {
  display: none;
  flex-direction: row;
}

.mobile-row-data {
  display: flex;
  flex-direction: column;
  flex: 2 1;
}

.mobile-table-arrow {
  margin: auto;
  margin-right: 3px;
}

.table-desktop-row {
  display: flex;
}

.table-mobile-label {
  font-size: 0.75rem;
  color: var(--meteorite-grey);
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 0.25rem;
  min-width: 2.125rem;
  align-self: flex-start;
}

@media all and (max-width: 768px) {
  .table-container {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x: hidden;
    border-top: 2px solid var(--meteorite-grey);
    border-bottom: 2px solid var(--meteorite-grey);
  }

  .table-mobile-row {
    display: flex;
  }

  .table-desktop-row {
    display: none;
  }

  tbody:last-child {
    border-top: none;
  }

  tbody:last-child {
    border-bottom: none;
  }

  td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    min-height: 0;
  }

  thead {
    display: none;
  }

  td:first-child {
    padding-left: 0rem;
  }

  th:first-child {
    padding-left: 0;
  }

  td.discipline {
    width: 100%;
  }

  td.project {
    width: 100%;
    margin: 0;
  }

  td.hours {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  td.comment {
    width: 100%;
  }

  td.options {
    display: none;
    width: 100%;
  }

  .allocation-frame {
    display: none;
  }

  .table-loader {
    margin-top: 2rem;
    align-items: center;
  }
}

.summary {
  margin-left: 61%;
  margin-top: 0.875rem;
}

.sum-remain {
  font-style: italic;
  font-weight: 400;
  margin-left: 0.4rem;
}

.sum {
  font-size: var(--default-font-size);
  font-weight: 600;
  color: var(--lunar-blue);
}

@media all and (max-width: 768px) {
  .summary {
    font-size: 0.875rem;
    margin-left: 0;
  }
}

.viewreports-wrapper {
  height: 100%;
  display: table-row;
}

.table-header {
  margin-bottom: 0.875rem;
}

.history-table {
  margin-bottom: 1.16875rem;
}

.view-loader {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  margin-top: 3rem;
}

.empty-history {
  position: absolute;
  top: 40%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -40%);
  font-weight: 400;
  font-size: 1rem;
  color: var(--space-grey);
  text-align: center;
}

.empty-history-header {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}

.empty-history-text {
  width: 17.5rem;
  margin-top: 1rem;
  font-size: var(--default-font-size);
}

.mobile-empty-icon {
  display: none;
}

.table-date {
  margin-left: 0.4375rem;
  margin-bottom: 1.125rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--deep-space);
}

@media all and (max-width: 768px) {
  .table-date {
    margin-left: 0;
    margin-bottom: 0.6875rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .desktop-empty-icon {
    display: none;
  }

  .mobile-empty-icon {
    display: block;
  }

  .table-header {
    display: none;
  }

  .history-table {
    margin-bottom: 3.1875rem;
  }
}

.container-cb {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: var(--default-font-size);
  font-weight: 600;
  color: var(--lunar-blue);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.checkmark-background {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--sky-blue);
  transition: 0.2s;
  opacity: 0.5;
}

.checkmark-background:hover {
  width: 2.25rem;
  height: 2.25rem;
  opacity: 0.4;
}

.checkbox-label {
  position: relative;
  top: -0.15rem;
  width: 20rem;
}

/* Hide the browser's default checkbox */
.container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border: 2px solid var(--space-grey);
  box-sizing: border-box;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.container-cb input:checked ~ .checkmark {
  background-color: var(--sunflare-orange);
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-cb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-cb .checkmark:after {
  background: url(/static/media/Vector.df508fa9.svg);
  background-repeat: no-repeat;
  position: relative;
  margin-top: 4px;
  margin-left: 4.2px;
  width: 0.75rem;
  height: 0.625rem;
}

.home_wrapper {
  height: 100%;
  display: table-row;
}

.date-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  text-align: left;
  color: var(--deep-space);
}

.mark-as-done {
  margin-left: 61%;
  margin-top: 1.5625rem;
  font-weight: 600;
  font-size: var(--default-font-size);
  color: var(--lunar-blue);
}

.import-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 0.9375rem;
  font-size: var(--default-font-size);
  color: var(--cresent-blue);
}

.import-button p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.add-project-mobile-button {
  display: none;
  width: 100%;
  margin-bottom: 2rem;
}

@media all and (max-width: 768px) {
  .home_wrapper .form {
    display: none !important;
  }
  .home_wrapper .import-button {
    display: none !important;
  }
  .home_wrapper .welcome-text {
    display: none !important;
  }
  .home_wrapper .mark-as-done {
    margin-left: 0;
    margin-top: 0.85625rem;
  }
  .home_wrapper .add-project-mobile-button {
    display: block;
  }
}

@media all and (max-width: 320px) {
  .home_wrapper .add-project-mobile-button {
    width: 17.5rem;
  }
}

@media all and (max-width: 280px) {
  .home_wrapper .add-project-mobile-button {
    width: 14.5rem;
  }
}

.form-input {
  font-size: 15.5px;
  font-family: 'Open Sans', 'Courier New', Courier, monospace;
  height: 2rem !important;
  width: 100%;
  padding: 0;
  padding-left: 0.5rem;
  background: var(--starlight-blue);
  border-radius: 3px;
  color: var(--lunar-blue);
  height: 1.875rem;
  outline: none;
  border: 1px solid var(--starlight-blue); /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box;
}

.form-input:focus {
  background-color: white;
  outline: none;
  box-shadow: 0 0 0pt 1px rgb(38, 132, 255);
}

.edit > input {
  background-color: white;
}

.form-input.disabled {
  opacity: 1;
  color: var(--space-grey);
}

.form-input::-webkit-input-placeholder {
  font-size: var(--default-font-size);
  color: var(--space-grey);
}

.form-input:-ms-input-placeholder {
  font-size: var(--default-font-size);
  color: var(--space-grey);
}

.form-input::-ms-input-placeholder {
  font-size: var(--default-font-size);
  color: var(--space-grey);
}

.form-input::placeholder {
  font-size: var(--default-font-size);
  color: var(--space-grey);
}

.form-input.disabled::-webkit-input-placeholder {
  color: var(--meteorite-grey);
}

.form-input.disabled:-ms-input-placeholder {
  color: var(--meteorite-grey);
}

.form-input.disabled::-ms-input-placeholder {
  color: var(--meteorite-grey);
}

.form-input.disabled::placeholder {
  color: var(--meteorite-grey);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.button {
  width: 100%;
  height: 2rem;
  background-color: var(--lunar-blue);
  color: white;
  font-family: Open Sans;
  font-size: var(--default-font-size);
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(32, 42, 62, 0.14),
    0px 3px 4px rgba(32, 42, 62, 0.12), 0px 1px 5px rgba(32, 42, 62, 0.2);
  transition: 0.1s;
}

.button:hover {
  background-color: var(--deep-space);
}

.button.disabled {
  background-color: var(--meteorite-grey);
  color: var(--sky-blue);
}

.input-label {
  position: absolute;
  top: -1.3rem;
  font-weight: 600;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
  color: var(--meteorite-grey);
}

.form {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.125rem;
}

.mobile-input {
  display: none;
}

.desktop-input {
  width: 100%;
  display: flex;
  position: relative;
}

.form-discipline {
  width: 30%;
  padding-right: 1rem;
}

.form-project {
  width: 30%;
  padding-right: 0.6875rem;
}

.form-hours {
  width: 7%;
  padding-right: 1rem;
}

.form-comment {
  width: 25%;
  padding-right: 1rem;
}

.add-button {
  min-width: 2.5625rem;
  width: 6.5%;
}

.input-checkbox {
  margin: 0;
  margin-right: 0.5rem;
}

.form-hours-mobile {
  display: none;
}

.form-comment-mobile {
  display: none;
}

@media all and (max-width: 768px) {
  .form {
    width: 100%;
    flex-direction: column;
  }

  .welcome-text {
    margin-bottom: 0.4375rem;
    font-size: 0.875rem;
  }

  .mobile-view-inputs {
    display: flex;
    flex-direction: row;
  }

  .desktop-input {
    display: none;
  }

  .input-label {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    top: 0;
  }

  .form-discipline {
    width: 100% !important;
    margin-bottom: 1.3125rem;
  }

  .form-project {
    width: 100% !important;
    margin-bottom: 1.3125rem;
  }

  .form-hours-mobile {
    display: block;
    width: 30% !important;
    padding-right: 1rem;
  }

  .form-comment-mobile {
    display: block;
    width: 70% !important;
    margin-bottom: 1.3125rem;
  }

  .form-hours {
    display: none;
  }

  .form-comment {
    display: none;
  }

  .add-button {
    width: 100% !important;
  }
}

.modal-container {
  padding: 0.75rem;
  padding-bottom: 0.7rem;
  width: 25rem;
}

.modal-header {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--lunar-blue);
  margin-bottom: 1.5rem;
}

.modal-body {
  display: flex;
  font-size: var(--default-font-size);
  margin-bottom: 2.5rem;
}

.modal-buttons {
  text-align: right;
}

.modal-buttons button {
  width: 9.25rem;
}

.modal-icon {
  margin-right: 1.0625rem;
}

@media all and (max-width: 768px) {
  .modal-container {
    padding: 0.5rem;
    width: 20rem;
  }

  .modal-buttons button {
    width: 7.25rem;
  }
}

@media all and (max-width: 360px) {
  .modal-container {
    padding: 0.3rem;
    width: 19rem;
  }

  .modal-buttons button {
    width: 5.25rem;
  }
}

@media all and (max-width: 320px) {
  .modal-container {
    padding: 0.3rem;
    width: 17rem;
  }

  .modal-buttons button {
    width: 5.25rem;
  }

  .modal-header {
    font-size: var(--default-font-size);
    font-weight: 600;
    color: var(--lunar-blue);
    margin-bottom: 1.5rem;
  }

  .modal-body {
    display: flex;
    font-size: 0.7rem;
    margin-bottom: 2.5rem;
  }
}

.header-wrapper {
  display: table-row;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--lunar-blue);
}

.header {
  position: relative;
  height: 4.5rem;
  color: white;
  padding: 0.9375rem;
  display: flex;
  flex-direction: row;
}

.header-logo {
  margin-left: 1.125rem;
}

.header-brand {
  font-size: 1.5rem;
  margin-left: 1.418125rem;
  margin-top: 0.55rem;
}

.header-options {
  font-size: 0.75rem;
  position: absolute;
  bottom: -0.9375rem;
  right: 1.6rem;
  font-weight: 400;
}

.header-option {
  position: relative;
  cursor: pointer;
}

.header-user {
  position: absolute;
  top: 0.6875rem;
  right: 1.6rem;
  font-size: 0.75rem;
  font-weight: 400;
}

.header-logout-icon {
  position: absolute;
  top: 0px;
  right: -1.3rem;
}

.mobile-hamburger-icon {
  display: none;
  justify-content: center;
  align-self: center;
  padding-left: 1rem;
}

.mobile-hamburger-container {
  position: fixed;
  box-sizing: border-box;
  background: var(--lunar-blue);
  height: 100%;
  text-align: left;
  padding: 1rem;
  min-width: 0px;
  width: 0px;
  top: 0;
  left: -2.5rem;
  z-index: 999;
  transition-property: all;
  transition-duration: 0.35s;
  overflow: hidden;
  box-shadow: 13px 0px 10px rgba(32, 42, 62, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-hamburger-container.open {
  left: 0;
  width: 80%;
}

.mobile-hamburger-header {
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--starlight-blue);
  padding: 0;
}

.mobile-hamburger-header > div {
  margin-right: 1.71875rem;
}

.mobile-hamburger-item {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-transform: uppercase;
  font-size: 1rem;
  width: 90%;
  font-weight: 400;
  padding: 0.6rem;
  margin-top: 1.525rem;
  border: 2px solid var(--lunar-blue);
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
}

.mobile-hamburger-item.selected {
  border: 2px solid var(--sunflare-orange);
  border-radius: 3px;
}

.mobile-hamburger-user {
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.mobile-header {
  display: none;
  font-size: 1.125rem;
  font-weight: 600;
  align-self: center;
  text-transform: uppercase;
  padding-left: 2.15625rem;
}

.top-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.bottom-content {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: right;
}

.mobile-brand-hamburger-header {
  background-image: url(/static/media/LogoAndHeader.66957551.svg); /* The image used */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  width: 100%;
  height: 80px;
}

.logo-and-header {
  width: 100%;
}

@media all and (min-width: 1280px) {
  .header {
    margin-left: auto;
    margin-right: auto;
    width: 78.625rem;
  }
}

@media all and (max-width: 768px) {
  .header {
    height: 3.5rem;
    padding: 0;
    margin: 0;
  }

  .mobile-hamburger-icon {
    display: block;
  }
  .mobile-header {
    display: block;
  }
  .header-brand {
    display: none;
  }
  .header-logo {
    display: none;
  }

  .header-options {
    display: none;
  }

  .header-user {
    display: none;
  }
}

@media all and (max-width: 320px) {
  .logo-and-header {
    width: 260px;
  }
}

@media all and (max-width: 280px) {
  .logo-and-header {
    width: 220px;
  }
}

.tab {
  height: 1.5625rem;
  color: white;
  font-size: var(--default-font-size);
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 7.5625rem;
}

.tab:hover {
  border-bottom: 0.25rem solid rgba(255, 166, 0, 0.342);
}

.tab.selected {
  font-weight: 600;
  border-bottom: 0.25rem solid var(--sunflare-orange);
}

.tabList {
  background-color: rgb(40, 57, 89);
  color: white;
  display: flex;
  flex-direction: row;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media all and (min-width: 1280px) {
  .tabs {
    width: 80rem;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (max-width: 768px) {
  .tabList {
    display: none;
  }
}

.addreport-mobile-wrapper {
  height: 100%;
  display: table-row;
}

.addreport-mobile-wrapper .import-button {
  padding-top: 1.625rem;
  padding-bottom: 1.125rem;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.editreport-mobile-wrapper {
  height: 100%;
  display: table-row;
}

.edit-form-buttons {
  display: flex;
  margin-top: 2.0625rem;
}

.editreport-mobile-wrapper .mobile-view-inputs {
  height: 3.125rem;
}

.error-container {
  position: absolute;
  top: 40%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -40%);
  font-weight: 400;
  font-size: 1rem;
  color: var(--space-grey);
  text-align: center;
  background-color: white;
  box-shadow: 0px 9px 12px rgba(32, 42, 62, 0.14),
    0px 3px 16px rgba(32, 42, 62, 0.12), 0px 5px 6px rgba(32, 42, 62, 0.2);
  border-radius: 5px;
  width: 27.3125rem;
  height: 18.6875rem;
}

a {
  color: var(--cresent-blue);
}

.error-icon {
  margin-top: 2.1875rem;
}

.error-header {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}

.error-text {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 15.3125rem;
  margin-top: 1rem;
  font-size: var(--default-font-size);
}
.error-icon-mobile {
  margin-top: 2.1875rem;
  display: none;
}

.errorpage-header {
  display: flex;
  height: 4.5rem;
  background-color: var(--lunar-blue);
  align-items: center;
}

.errorpage-logo {
  margin-left: 1.125rem;
  margin-top: auto;
  margin-bottom: auto;
}

@media all and (max-width: 768px) {
  .error-icon-mobile {
    display: block;
  }

  .error-icon {
    display: none;
  }
}

@media all and (max-width: 440px) {
  .error-container {
    width: 90%;
  }
}

@media all and (max-width: 300px) {
  .error-header {
    font-size: 1rem;
  }

  .error-text {
    width: 12.5rem;
    font-size: 0.5rem;
  }
}

/*!
  * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
  * Copyright 2015 Daniel Cardoso <@DanielCardoso>
  * Licensed under MIT
  */
.la-ball-grid-pulse,
.la-ball-grid-pulse > div {
  position: relative;
  box-sizing: border-box;
}

.la-ball-grid-pulse {
  display: block;
  font-size: 0;
}

.la-ball-grid-pulse.la-dark {
  color: #333;
}

.la-ball-grid-pulse.la-sunflare {
  color: var(--sunflare-orange);
}

.la-ball-grid-pulse > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-grid-pulse {
  width: 36px;
  height: 36px;
}

.la-ball-grid-pulse > div {
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
  animation-name: ball-grid-pulse;
  animation-iteration-count: infinite;
}

.la-ball-grid-pulse > div:nth-child(1) {
  animation-duration: 0.65s;
  animation-delay: 0.03s;
}

.la-ball-grid-pulse > div:nth-child(2) {
  animation-duration: 1.02s;
  animation-delay: 0.09s;
}

.la-ball-grid-pulse > div:nth-child(3) {
  animation-duration: 1.06s;
  animation-delay: -0.69s;
}

.la-ball-grid-pulse > div:nth-child(4) {
  animation-duration: 1.5s;
  animation-delay: -0.41s;
}

.la-ball-grid-pulse > div:nth-child(5) {
  animation-duration: 1.6s;
  animation-delay: 0.04s;
}

.la-ball-grid-pulse > div:nth-child(6) {
  animation-duration: 0.84s;
  animation-delay: 0.07s;
}

.la-ball-grid-pulse > div:nth-child(7) {
  animation-duration: 0.68s;
  animation-delay: -0.66s;
}

.la-ball-grid-pulse > div:nth-child(8) {
  animation-duration: 0.93s;
  animation-delay: -0.76s;
}

.la-ball-grid-pulse > div:nth-child(9) {
  animation-duration: 1.24s;
  animation-delay: -0.76s;
}

.la-ball-grid-pulse.la-sm {
  width: 18px;
  height: 18px;
}

.la-ball-grid-pulse.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 1px;
}

.la-ball-grid-pulse.la-2x {
  width: 72px;
  height: 72px;
}

.la-ball-grid-pulse.la-2x > div {
  width: 16px;
  height: 16px;
  margin: 4px;
}

.la-ball-grid-pulse.la-3x {
  width: 108px;
  height: 108px;
}

.la-ball-grid-pulse.la-3x > div {
  width: 24px;
  height: 24px;
  margin: 6px;
}

/*
      * Animation
      */
@keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*!
    * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
    * Copyright 2015 Daniel Cardoso <@DanielCardoso>
    * Licensed under MIT
    */
.la-ball-spin-clockwise-fade,
.la-ball-spin-clockwise-fade > div {
  position: relative;
  box-sizing: border-box;
}

.la-ball-spin-clockwise-fade {
  display: block;
  font-size: 0;
}

.la-ball-spin-clockwise-fade.la-dark {
  color: #333;
}

.la-ball-spin-clockwise-fade > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-spin-clockwise-fade {
  width: 32px;
  height: 32px;
}

.la-ball-spin-clockwise-fade > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 100%;
  animation: ball-spin-clockwise-fade 1s infinite linear;
}

.la-ball-spin-clockwise-fade > div:nth-child(1) {
  top: 5%;
  left: 50%;
  animation-delay: -0.875s;
}

.la-ball-spin-clockwise-fade > div:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  animation-delay: -0.75s;
}

.la-ball-spin-clockwise-fade > div:nth-child(3) {
  top: 50%;
  left: 95%;
  animation-delay: -0.625s;
}

.la-ball-spin-clockwise-fade > div:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  animation-delay: -0.5s;
}

.la-ball-spin-clockwise-fade > div:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  animation-delay: -0.375s;
}

.la-ball-spin-clockwise-fade > div:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  animation-delay: -0.25s;
}

.la-ball-spin-clockwise-fade > div:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  animation-delay: -0.125s;
}

.la-ball-spin-clockwise-fade > div:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  animation-delay: 0s;
}

.la-ball-spin-clockwise-fade.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-spin-clockwise-fade.la-sm > div {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}

.la-ball-spin-clockwise-fade.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-spin-clockwise-fade.la-2x > div {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

.la-ball-spin-clockwise-fade.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-spin-clockwise-fade.la-3x > div {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}

/*
    * Animation
    */
@keyframes ball-spin-clockwise-fade {
  50% {
    opacity: 0.25;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*!
    * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
    * Copyright 2015 Daniel Cardoso <@DanielCardoso>
    * Licensed under MIT
    */
.la-line-scale,
.la-line-scale > div {
  position: relative;
  box-sizing: border-box;
}

.la-line-scale {
  display: block;
  font-size: 0;
}

.la-line-scale.la-dark {
  color: #333;
}

.la-line-scale > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-line-scale {
  width: 40px;
  height: 32px;
}

.la-line-scale > div {
  width: 4px;
  height: 32px;
  margin: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  animation: line-scale 1.2s infinite ease;
}

.la-line-scale > div:nth-child(1) {
  animation-delay: -1.2s;
}

.la-line-scale > div:nth-child(2) {
  animation-delay: -1.1s;
}

.la-line-scale > div:nth-child(3) {
  animation-delay: -1s;
}

.la-line-scale > div:nth-child(4) {
  animation-delay: -0.9s;
}

.la-line-scale > div:nth-child(5) {
  animation-delay: -0.8s;
}

.la-line-scale.la-sm {
  width: 20px;
  height: 16px;
}

.la-line-scale.la-sm > div {
  width: 2px;
  height: 16px;
  margin: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.la-line-scale.la-2x {
  width: 80px;
  height: 64px;
}

.la-line-scale.la-2x > div {
  width: 8px;
  height: 64px;
  margin: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.la-line-scale.la-3x {
  width: 120px;
  height: 96px;
}

.la-line-scale.la-3x > div {
  width: 12px;
  height: 96px;
  margin: 6px;
  margin-top: 0;
  margin-bottom: 0;
}

/*
    * Animation
    */
@keyframes line-scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}


.error-container {
  position: absolute;
  top: 40%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -40%);
  font-weight: 400;
  font-size: 1rem;
  color: var(--space-grey);
  text-align: center;
  background-color: white;
  box-shadow: 0px 9px 12px rgba(32, 42, 62, 0.14),
    0px 3px 16px rgba(32, 42, 62, 0.12), 0px 5px 6px rgba(32, 42, 62, 0.2);
  border-radius: 5px;
  width: 27.3125rem;
  height: 18.6875rem;
}

a {
  color: var(--cresent-blue);
}

.error-icon {
  margin-top: 2.1875rem;
}

.error-header {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}

.error-text {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 15.3125rem;
  margin-top: 1rem;
  font-size: var(--default-font-size);
}
.error-icon-mobile {
  margin-top: 2.1875rem;
  display: none;
}

.errorpage-header {
  display: flex;
  height: 4.5rem;
  background-color: var(--lunar-blue);
  align-items: center;
}

.errorpage-logo {
  margin-left: 1.125rem;
  margin-top: auto;
  margin-bottom: auto;
}

@media all and (max-width: 768px) {
  .error-icon-mobile {
    display: block;
  }

  .error-icon {
    display: none;
  }
}

@media all and (max-width: 440px) {
  .error-container {
    width: 90%;
  }
}

@media all and (max-width: 300px) {
  .error-header {
    font-size: 1rem;
  }

  .error-text {
    width: 12.5rem;
    font-size: 0.5rem;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
  touch-action: pan-x pan-y;
  height: 100%;

  --lunar-blue: #273859;
  --meteorite-grey: #7f8fb0;
  --sunflare-orange: #ff9714;
  --lunar-dust: #eaeffa;
  --starlight-blue: #e5ebf9;
  --space-grey: #526384;
  --sky-blue: #cedcf9;
  --cresent-blue: #006eab;
  --deep-space: #122344;
  --dark-space: #00021a;
  --comet-red: rgba(252, 60, 48, 0.15);
  --red: #fc3c30;
  --black: #202a3e;

  --default-font-size: 0.875rem;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--lunar-dust);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  position: relative;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: calc(100% - 1rem);
  background: white;
  border-radius: 5px;
  margin: 0.5rem;
  padding-left: 1.0625rem;
  padding-right: 1.0625rem;
  padding-top: 1.6875rem;
  border: none;
  box-shadow: 0px 0px 15px rgba(214, 214, 214, 0.7);
}

.content-container {
  height: fit-content;
  width: 100%;
}

.welcome-text {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2.2rem;
  line-height: 1.531875rem;
}

.separator {
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--meteorite-grey);
}

.error {
  background: var(--comet-red) !important;
  border: 1px solid var(--red) !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-size: 1rem;
  color: var(--space-grey);
  text-align: center;
}

@media all and (min-width: 1280px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 76.625rem;
  }
}

@media all and (min-width: 768px) {
  .container {
    padding-bottom: 1.6875rem;
  }
}

@media all and (max-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: rgb(255, 255, 255, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 100px;
    background-color: var(--meteorite-grey);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

@media all and (max-height: 420px) {
  .content-container {
    padding-bottom: 6rem;
  }
}

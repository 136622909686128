.header-wrapper {
  display: table-row;
  height: fit-content;
  background-color: var(--lunar-blue);
}

.header {
  position: relative;
  height: 4.5rem;
  color: white;
  padding: 0.9375rem;
  display: flex;
  flex-direction: row;
}

.header-logo {
  margin-left: 1.125rem;
}

.header-brand {
  font-size: 1.5rem;
  margin-left: 1.418125rem;
  margin-top: 0.55rem;
}

.header-options {
  font-size: 0.75rem;
  position: absolute;
  bottom: -0.9375rem;
  right: 1.6rem;
  font-weight: 400;
}

.header-option {
  position: relative;
  cursor: pointer;
}

.header-user {
  position: absolute;
  top: 0.6875rem;
  right: 1.6rem;
  font-size: 0.75rem;
  font-weight: 400;
}

.header-logout-icon {
  position: absolute;
  top: 0px;
  right: -1.3rem;
}

.mobile-hamburger-icon {
  display: none;
  justify-content: center;
  align-self: center;
  padding-left: 1rem;
}

.mobile-hamburger-container {
  position: fixed;
  box-sizing: border-box;
  background: var(--lunar-blue);
  height: 100%;
  text-align: left;
  padding: 1rem;
  min-width: 0px;
  width: 0px;
  top: 0;
  left: -2.5rem;
  z-index: 999;
  transition-property: all;
  transition-duration: 0.35s;
  overflow: hidden;
  box-shadow: 13px 0px 10px rgba(32, 42, 62, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-hamburger-container.open {
  left: 0;
  width: 80%;
}

.mobile-hamburger-header {
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--starlight-blue);
  padding: 0;
}

.mobile-hamburger-header > div {
  margin-right: 1.71875rem;
}

.mobile-hamburger-item {
  height: fit-content;
  text-transform: uppercase;
  font-size: 1rem;
  width: 90%;
  font-weight: 400;
  padding: 0.6rem;
  margin-top: 1.525rem;
  border: 2px solid var(--lunar-blue);
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
}

.mobile-hamburger-item.selected {
  border: 2px solid var(--sunflare-orange);
  border-radius: 3px;
}

.mobile-hamburger-user {
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.mobile-header {
  display: none;
  font-size: 1.125rem;
  font-weight: 600;
  align-self: center;
  text-transform: uppercase;
  padding-left: 2.15625rem;
}

.top-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.bottom-content {
  width: 100%;
  height: fit-content;
  text-align: right;
}

.mobile-brand-hamburger-header {
  background-image: url('LogoAndHeader.svg'); /* The image used */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  width: 100%;
  height: 80px;
}

.logo-and-header {
  width: 100%;
}

@media all and (min-width: 1280px) {
  .header {
    margin-left: auto;
    margin-right: auto;
    width: 78.625rem;
  }
}

@media all and (max-width: 768px) {
  .header {
    height: 3.5rem;
    padding: 0;
    margin: 0;
  }

  .mobile-hamburger-icon {
    display: block;
  }
  .mobile-header {
    display: block;
  }
  .header-brand {
    display: none;
  }
  .header-logo {
    display: none;
  }

  .header-options {
    display: none;
  }

  .header-user {
    display: none;
  }
}

@media all and (max-width: 320px) {
  .logo-and-header {
    width: 260px;
  }
}

@media all and (max-width: 280px) {
  .logo-and-header {
    width: 220px;
  }
}

.addreport-mobile-wrapper {
  height: 100%;
  display: table-row;
}

.addreport-mobile-wrapper .import-button {
  padding-top: 1.625rem;
  padding-bottom: 1.125rem;
  cursor: pointer;
  user-select: none;
}

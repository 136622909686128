.home_wrapper {
  height: 100%;
  display: table-row;
}

.date-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  text-align: left;
  color: var(--deep-space);
}

.mark-as-done {
  margin-left: 61%;
  margin-top: 1.5625rem;
  font-weight: 600;
  font-size: var(--default-font-size);
  color: var(--lunar-blue);
}

.import-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 0.9375rem;
  font-size: var(--default-font-size);
  color: var(--cresent-blue);
}

.import-button p {
  width: fit-content;
  cursor: pointer;
}

.add-project-mobile-button {
  display: none;
  width: 100%;
  margin-bottom: 2rem;
}

@media all and (max-width: 768px) {
  .home_wrapper .form {
    display: none !important;
  }
  .home_wrapper .import-button {
    display: none !important;
  }
  .home_wrapper .welcome-text {
    display: none !important;
  }
  .home_wrapper .mark-as-done {
    margin-left: 0;
    margin-top: 0.85625rem;
  }
  .home_wrapper .add-project-mobile-button {
    display: block;
  }
}

@media all and (max-width: 320px) {
  .home_wrapper .add-project-mobile-button {
    width: 17.5rem;
  }
}

@media all and (max-width: 280px) {
  .home_wrapper .add-project-mobile-button {
    width: 14.5rem;
  }
}

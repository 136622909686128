table {
  border-collapse: collapse;
  width: 100%;
  font-size: var(--default-font-size);
}

.disabled {
  opacity: 0.6;
}

.table-container {
  margin: 0;
  padding: 0;
}

.table-loader {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-top: 5rem;
}

td {
  width: 14.375rem;
  min-height: 47px;
  display: flex;
  align-items: center;
  color: var(--black);
}

th {
  width: 14.375rem;
  text-align: left;
  font-size: 0.75rem;
  color: #7f8fb0;
  font-weight: 600;
  text-transform: uppercase;
}

th:first-child {
  padding-left: 0.4375rem;
}

th.discipline {
  width: 30%;
}

th.project {
  width: 30%;
}

th.hours {
  width: 8.5%;
}

th.comment {
  width: 27%;
}

th.options {
  width: 4%;
  min-width: 4%;
}

td.discipline {
  width: 30%;
  margin-right: 0.3125rem;
}

td.project {
  width: 30%;
}

td.hours {
  width: 8.5%;
}

td.comment {
  width: 27%;
}

td.options {
  text-align: center;
  width: 4%;
  min-width: 4%;
}

td * {
  display: flex;
  align-items: center;
}

.column-content {
  position: relative;
  width: 90%;
  word-wrap: break-word;
  align-self: center;
  vertical-align: middle;
  height: 100%;
  padding-left: 0.4rem;
}

.column-content.first {
  padding-left: 0.5rem;
}

.column-content.framed {
  height: 70%;
  width: 70% !important;
  padding-left: 0.4rem;
  border-radius: 3px;
  padding-left: -2px;
  border: 1px solid var(--meteorite-grey);
  cursor: pointer;
}

.allocation-input {
  display: flex;
  align-items: center;
  width: 70%;
  height: 2rem;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0.0625rem solid var(--meteorite-grey);
  border-radius: 0.1875rem;
  padding-left: 0.1rem;
}

.allocation-input:active {
  border: none;
}

tbody tr {
  transition: 0.2s;
  border-bottom: 1px solid rgba(172, 177, 188, 0.5);
}

tbody:last-child {
  border-top: 2px solid var(--meteorite-grey);
}

tbody:last-child {
  border-bottom: 2px solid var(--meteorite-grey);
}

.table-hover:hover {
  background-color: var(--sky-blue);
}

.table-delete {
  cursor: pointer;
  height: 1rem;
}

.empty-row {
  margin-top: 2.875rem;
}

.table-mobile-row {
  display: none;
  flex-direction: row;
}

.mobile-row-data {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.mobile-table-arrow {
  margin: auto;
  margin-right: 3px;
}

.table-desktop-row {
  display: flex;
}

.table-mobile-label {
  font-size: 0.75rem;
  color: var(--meteorite-grey);
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 0.25rem;
  min-width: 2.125rem;
  align-self: flex-start;
}

@media all and (max-width: 768px) {
  .table-container {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x: hidden;
    border-top: 2px solid var(--meteorite-grey);
    border-bottom: 2px solid var(--meteorite-grey);
  }

  .table-mobile-row {
    display: flex;
  }

  .table-desktop-row {
    display: none;
  }

  tbody:last-child {
    border-top: none;
  }

  tbody:last-child {
    border-bottom: none;
  }

  td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    min-height: 0;
  }

  thead {
    display: none;
  }

  td:first-child {
    padding-left: 0rem;
  }

  th:first-child {
    padding-left: 0;
  }

  td.discipline {
    width: 100%;
  }

  td.project {
    width: 100%;
    margin: 0;
  }

  td.hours {
    width: 100%;
    height: fit-content;
  }

  td.comment {
    width: 100%;
  }

  td.options {
    display: none;
    width: 100%;
  }

  .allocation-frame {
    display: none;
  }

  .table-loader {
    margin-top: 2rem;
    align-items: center;
  }
}

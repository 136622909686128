.container-cb {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: var(--default-font-size);
  font-weight: 600;
  color: var(--lunar-blue);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
  height: fit-content;
}

.checkmark-background {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--sky-blue);
  transition: 0.2s;
  opacity: 0.5;
}

.checkmark-background:hover {
  width: 2.25rem;
  height: 2.25rem;
  opacity: 0.4;
}

.checkbox-label {
  position: relative;
  top: -0.15rem;
  width: 20rem;
}

/* Hide the browser's default checkbox */
.container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border: 2px solid var(--space-grey);
  box-sizing: border-box;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.container-cb input:checked ~ .checkmark {
  background-color: var(--sunflare-orange);
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-cb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-cb .checkmark:after {
  background: url(./Vector.svg);
  background-repeat: no-repeat;
  position: relative;
  margin-top: 4px;
  margin-left: 4.2px;
  width: 0.75rem;
  height: 0.625rem;
}

/*!
  * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
  * Copyright 2015 Daniel Cardoso <@DanielCardoso>
  * Licensed under MIT
  */
.la-ball-grid-pulse,
.la-ball-grid-pulse > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-grid-pulse {
  display: block;
  font-size: 0;
}

.la-ball-grid-pulse.la-dark {
  color: #333;
}

.la-ball-grid-pulse.la-sunflare {
  color: var(--sunflare-orange);
}

.la-ball-grid-pulse > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-grid-pulse {
  width: 36px;
  height: 36px;
}

.la-ball-grid-pulse > div {
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation-name: ball-grid-pulse;
  -moz-animation-name: ball-grid-pulse;
  -o-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.la-ball-grid-pulse > div:nth-child(1) {
  -webkit-animation-duration: 0.65s;
  -moz-animation-duration: 0.65s;
  -o-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-delay: 0.03s;
  -moz-animation-delay: 0.03s;
  -o-animation-delay: 0.03s;
  animation-delay: 0.03s;
}

.la-ball-grid-pulse > div:nth-child(2) {
  -webkit-animation-duration: 1.02s;
  -moz-animation-duration: 1.02s;
  -o-animation-duration: 1.02s;
  animation-duration: 1.02s;
  -webkit-animation-delay: 0.09s;
  -moz-animation-delay: 0.09s;
  -o-animation-delay: 0.09s;
  animation-delay: 0.09s;
}

.la-ball-grid-pulse > div:nth-child(3) {
  -webkit-animation-duration: 1.06s;
  -moz-animation-duration: 1.06s;
  -o-animation-duration: 1.06s;
  animation-duration: 1.06s;
  -webkit-animation-delay: -0.69s;
  -moz-animation-delay: -0.69s;
  -o-animation-delay: -0.69s;
  animation-delay: -0.69s;
}

.la-ball-grid-pulse > div:nth-child(4) {
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: -0.41s;
  -moz-animation-delay: -0.41s;
  -o-animation-delay: -0.41s;
  animation-delay: -0.41s;
}

.la-ball-grid-pulse > div:nth-child(5) {
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
  -o-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-delay: 0.04s;
  -moz-animation-delay: 0.04s;
  -o-animation-delay: 0.04s;
  animation-delay: 0.04s;
}

.la-ball-grid-pulse > div:nth-child(6) {
  -webkit-animation-duration: 0.84s;
  -moz-animation-duration: 0.84s;
  -o-animation-duration: 0.84s;
  animation-duration: 0.84s;
  -webkit-animation-delay: 0.07s;
  -moz-animation-delay: 0.07s;
  -o-animation-delay: 0.07s;
  animation-delay: 0.07s;
}

.la-ball-grid-pulse > div:nth-child(7) {
  -webkit-animation-duration: 0.68s;
  -moz-animation-duration: 0.68s;
  -o-animation-duration: 0.68s;
  animation-duration: 0.68s;
  -webkit-animation-delay: -0.66s;
  -moz-animation-delay: -0.66s;
  -o-animation-delay: -0.66s;
  animation-delay: -0.66s;
}

.la-ball-grid-pulse > div:nth-child(8) {
  -webkit-animation-duration: 0.93s;
  -moz-animation-duration: 0.93s;
  -o-animation-duration: 0.93s;
  animation-duration: 0.93s;
  -webkit-animation-delay: -0.76s;
  -moz-animation-delay: -0.76s;
  -o-animation-delay: -0.76s;
  animation-delay: -0.76s;
}

.la-ball-grid-pulse > div:nth-child(9) {
  -webkit-animation-duration: 1.24s;
  -moz-animation-duration: 1.24s;
  -o-animation-duration: 1.24s;
  animation-duration: 1.24s;
  -webkit-animation-delay: -0.76s;
  -moz-animation-delay: -0.76s;
  -o-animation-delay: -0.76s;
  animation-delay: -0.76s;
}

.la-ball-grid-pulse.la-sm {
  width: 18px;
  height: 18px;
}

.la-ball-grid-pulse.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 1px;
}

.la-ball-grid-pulse.la-2x {
  width: 72px;
  height: 72px;
}

.la-ball-grid-pulse.la-2x > div {
  width: 16px;
  height: 16px;
  margin: 4px;
}

.la-ball-grid-pulse.la-3x {
  width: 108px;
  height: 108px;
}

.la-ball-grid-pulse.la-3x > div {
  width: 24px;
  height: 24px;
  margin: 6px;
}

/*
      * Animation
      */
@-webkit-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -moz-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -o-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    -o-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

/*!
    * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
    * Copyright 2015 Daniel Cardoso <@DanielCardoso>
    * Licensed under MIT
    */
.la-ball-spin-clockwise-fade,
.la-ball-spin-clockwise-fade > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-spin-clockwise-fade {
  display: block;
  font-size: 0;
}

.la-ball-spin-clockwise-fade.la-dark {
  color: #333;
}

.la-ball-spin-clockwise-fade > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-spin-clockwise-fade {
  width: 32px;
  height: 32px;
}

.la-ball-spin-clockwise-fade > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 100%;
  -webkit-animation: ball-spin-clockwise-fade 1s infinite linear;
  -moz-animation: ball-spin-clockwise-fade 1s infinite linear;
  -o-animation: ball-spin-clockwise-fade 1s infinite linear;
  animation: ball-spin-clockwise-fade 1s infinite linear;
}

.la-ball-spin-clockwise-fade > div:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -0.875s;
  -moz-animation-delay: -0.875s;
  -o-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.la-ball-spin-clockwise-fade > div:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.75s;
  -moz-animation-delay: -0.75s;
  -o-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.la-ball-spin-clockwise-fade > div:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -0.625s;
  -moz-animation-delay: -0.625s;
  -o-animation-delay: -0.625s;
  animation-delay: -0.625s;
}

.la-ball-spin-clockwise-fade > div:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.la-ball-spin-clockwise-fade > div:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -0.375s;
  -moz-animation-delay: -0.375s;
  -o-animation-delay: -0.375s;
  animation-delay: -0.375s;
}

.la-ball-spin-clockwise-fade > div:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.la-ball-spin-clockwise-fade > div:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -0.125s;
  -moz-animation-delay: -0.125s;
  -o-animation-delay: -0.125s;
  animation-delay: -0.125s;
}

.la-ball-spin-clockwise-fade > div:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.la-ball-spin-clockwise-fade.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-spin-clockwise-fade.la-sm > div {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}

.la-ball-spin-clockwise-fade.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-spin-clockwise-fade.la-2x > div {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

.la-ball-spin-clockwise-fade.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-spin-clockwise-fade.la-3x > div {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}

/*
    * Animation
    */
@-webkit-keyframes ball-spin-clockwise-fade {
  50% {
    opacity: 0.25;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes ball-spin-clockwise-fade {
  50% {
    opacity: 0.25;
    -moz-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes ball-spin-clockwise-fade {
  50% {
    opacity: 0.25;
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-spin-clockwise-fade {
  50% {
    opacity: 0.25;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

/*!
    * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
    * Copyright 2015 Daniel Cardoso <@DanielCardoso>
    * Licensed under MIT
    */
.la-line-scale,
.la-line-scale > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-line-scale {
  display: block;
  font-size: 0;
}

.la-line-scale.la-dark {
  color: #333;
}

.la-line-scale > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-line-scale {
  width: 40px;
  height: 32px;
}

.la-line-scale > div {
  width: 4px;
  height: 32px;
  margin: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-animation: line-scale 1.2s infinite ease;
  -moz-animation: line-scale 1.2s infinite ease;
  -o-animation: line-scale 1.2s infinite ease;
  animation: line-scale 1.2s infinite ease;
}

.la-line-scale > div:nth-child(1) {
  -webkit-animation-delay: -1.2s;
  -moz-animation-delay: -1.2s;
  -o-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.la-line-scale > div:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  -o-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.la-line-scale > div:nth-child(3) {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
}

.la-line-scale > div:nth-child(4) {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.la-line-scale > div:nth-child(5) {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.la-line-scale.la-sm {
  width: 20px;
  height: 16px;
}

.la-line-scale.la-sm > div {
  width: 2px;
  height: 16px;
  margin: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.la-line-scale.la-2x {
  width: 80px;
  height: 64px;
}

.la-line-scale.la-2x > div {
  width: 8px;
  height: 64px;
  margin: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.la-line-scale.la-3x {
  width: 120px;
  height: 96px;
}

.la-line-scale.la-3x > div {
  width: 12px;
  height: 96px;
  margin: 6px;
  margin-top: 0;
  margin-bottom: 0;
}

/*
    * Animation
    */
@-webkit-keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -moz-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-o-keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -o-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -moz-transform: scaleY(0.4);
    -o-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

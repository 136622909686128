.input-label {
  position: absolute;
  top: -1.3rem;
  font-weight: 600;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
  color: var(--meteorite-grey);
}

.form {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.125rem;
}

.mobile-input {
  display: none;
}

.desktop-input {
  width: 100%;
  display: flex;
  position: relative;
}

.form-discipline {
  width: 30%;
  padding-right: 1rem;
}

.form-project {
  width: 30%;
  padding-right: 0.6875rem;
}

.form-hours {
  width: 7%;
  padding-right: 1rem;
}

.form-comment {
  width: 25%;
  padding-right: 1rem;
}

.add-button {
  min-width: 2.5625rem;
  width: 6.5%;
}

.input-checkbox {
  margin: 0;
  margin-right: 0.5rem;
}

.form-hours-mobile {
  display: none;
}

.form-comment-mobile {
  display: none;
}

@media all and (max-width: 768px) {
  .form {
    width: 100%;
    flex-direction: column;
  }

  .welcome-text {
    margin-bottom: 0.4375rem;
    font-size: 0.875rem;
  }

  .mobile-view-inputs {
    display: flex;
    flex-direction: row;
  }

  .desktop-input {
    display: none;
  }

  .input-label {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    top: 0;
  }

  .form-discipline {
    width: 100% !important;
    margin-bottom: 1.3125rem;
  }

  .form-project {
    width: 100% !important;
    margin-bottom: 1.3125rem;
  }

  .form-hours-mobile {
    display: block;
    width: 30% !important;
    padding-right: 1rem;
  }

  .form-comment-mobile {
    display: block;
    width: 70% !important;
    margin-bottom: 1.3125rem;
  }

  .form-hours {
    display: none;
  }

  .form-comment {
    display: none;
  }

  .add-button {
    width: 100% !important;
  }
}

.button {
  width: 100%;
  height: 2rem;
  background-color: var(--lunar-blue);
  color: white;
  font-family: Open Sans;
  font-size: var(--default-font-size);
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(32, 42, 62, 0.14),
    0px 3px 4px rgba(32, 42, 62, 0.12), 0px 1px 5px rgba(32, 42, 62, 0.2);
  transition: 0.1s;
}

.button:hover {
  background-color: var(--deep-space);
}

.button.disabled {
  background-color: var(--meteorite-grey);
  color: var(--sky-blue);
}

.editreport-mobile-wrapper {
  height: 100%;
  display: table-row;
}

.edit-form-buttons {
  display: flex;
  margin-top: 2.0625rem;
}

.editreport-mobile-wrapper .mobile-view-inputs {
  height: 3.125rem;
}

.form-input {
  font-size: 15.5px;
  font-family: 'Open Sans', 'Courier New', Courier, monospace;
  height: 2rem !important;
  width: 100%;
  padding: 0;
  padding-left: 0.5rem;
  background: var(--starlight-blue);
  border-radius: 3px;
  color: var(--lunar-blue);
  height: 1.875rem;
  outline: none;
  border: 1px solid var(--starlight-blue);
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.form-input:focus {
  background-color: white;
  outline: none;
  box-shadow: 0 0 0pt 1px rgb(38, 132, 255);
}

.edit > input {
  background-color: white;
}

.form-input.disabled {
  opacity: 1;
  color: var(--space-grey);
}

.form-input::placeholder {
  font-size: var(--default-font-size);
  color: var(--space-grey);
}

.form-input.disabled::placeholder {
  color: var(--meteorite-grey);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
